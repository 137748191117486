import React from 'react'

import { FileInput } from '@blueprintjs/core'
import { inject, observer } from 'mobx-react'
import { classList } from 'react-classlist-helper'

import DndArea from '~/client/src/desktop/components/DndArea/DndArea'
import DesktopInitialState from '~/client/src/desktop/stores/DesktopInitialState'
import * as Icons from '~/client/src/shared/components/Icons'
import { Loader } from '~/client/src/shared/components/Loader'
import MenuCloser from '~/client/src/shared/components/MenuCloser'
import StruxhubInput from '~/client/src/shared/components/StruxhubInputs/StruxhubInput'
import FileType from '~/client/src/shared/enums/FileType'
import Localization from '~/client/src/shared/localization/LocalizationManager'
import { EMPTY_STRING } from '~/client/src/shared/utils/usefulStrings'

import MapViewItemsSetupStore from '../../../stores/MapViewItemsSetup.store'
import SitemapControlStore from '../../../stores/SitemapControl.store'

interface IProps {
  store: SitemapControlStore
  shouldFixTopPosition?: boolean

  mapViewItemsSetupStore?: MapViewItemsSetupStore
  state?: DesktopInitialState
}

const mapName = 'Plan name'
const dragMapFileFileHere = 'Drag Map File here'
const tilesetUploaded = 'Shapefile uploaded'
const planUploaded = 'Plan uploaded'
const uploadMapFile = 'Upload Map File'
const browseYourHardDrive = 'Browse your hard drive'
const EXTENSIONS_SEPARATOR = ','
const TILESET_EXTENSIONS = ['.zip', '.geojson', '.geojsonld']
const PLAN_EXTENSIONS = ['.pdf', '.png', '.tif']
const MAX_NAME_LENGTH = 64

const TILESET_NUMBER_ERROR = 'You have uploaded too many tilesets'
const TILESET_SIZE_ERROR = 'You have uploaded too large a file'
const TILESET_NAME_LENGTH_ERROR = `Tileset name too long. Max size ${MAX_NAME_LENGTH} symbols.`

@inject('state')
@observer
export default class UploadMapFileDialog extends React.Component<IProps> {
  private get isSaveButtonActive(): boolean {
    const { tilesetFile, isUploading, sitemapName, basemap } = this.props.store
    return (
      (!!tilesetFile && !this.hasSomeError) ||
      (!isUploading && !!sitemapName && !!basemap)
    )
  }

  private get isTilesetsDisabled(): boolean {
    return this.props.state.isTilesetsDisabled
  }

  private get acceptedExtensions(): string[] {
    return this.isTilesetsDisabled
      ? [...PLAN_EXTENSIONS]
      : [...TILESET_EXTENSIONS, ...PLAN_EXTENSIONS]
  }

  private get acceptedExtensionsText(): string {
    return `(${this.acceptedExtensions.join(
      `${EXTENSIONS_SEPARATOR} `,
    )} accepted)`
  }

  public componentWillUnmount(): void {
    this.props.store.resetTileFile()
  }

  public render(): JSX.Element {
    const { shouldFixTopPosition } = this.props
    const {
      isUploading,
      basemap,
      sitemapName,
      toggleCreateMapFileMenu,
      uploadTileFile,
      setPdfOrCreateSitemap,
      changeMapName,
    } = this.props.store

    const onSave = basemap ? setPdfOrCreateSitemap : uploadTileFile

    return (
      <MenuCloser
        className={classList({
          'absolute map-upload-block tilesets-upload': true,
          'fixed-top': shouldFixTopPosition,
        })}
        closeMenu={toggleCreateMapFileMenu}
      >
        <div className="col">
          <div className="text size24 mb30">{uploadMapFile}</div>
          <div className="row y-start">
            <div>
              <div className="general-map-setup-dnd-area">
                <DndArea
                  fileType={FileType.Image}
                  accept={this.acceptedExtensions}
                  title={this.title}
                  onDrop={this.setMapFile}
                  className="upload-schedule-dnd-container col x-center y-center"
                />
                {this.renderBrowseComputerLine()}
              </div>
            </div>
          </div>
          {!basemap && !this.isTilesetsDisabled && (
            <div className="text large primary mt5">{this.limitsText}</div>
          )}
          {basemap && (
            <div className="row mt5 relative overflow-hidden">
              <StruxhubInput
                id="sitemap-name-input"
                label={mapName}
                isRequired={true}
                value={sitemapName}
                onChange={changeMapName}
              />
            </div>
          )}
          <div
            className={classList({
              'submit-button light pa10 full-width mt20': true,
              'inactive-element unclickable-element': !this.isSaveButtonActive,
            })}
            onClick={onSave}
          >
            {isUploading ? <Loader /> : Localization.translator.save}
          </div>
          {this.hasSomeError && (
            <div className="text red bold center mt5">{this.errorText}</div>
          )}
        </div>
      </MenuCloser>
    )
  }

  private get limitsText(): string {
    const { maxNumber, maxSize } = this.props.state.tilesetsConfiguration
    return `You can upload up to ${maxNumber} tilesets of up to ${maxSize} each.`
  }

  private get errorText(): string {
    if (!this.isSizeValid) {
      return TILESET_SIZE_ERROR
    }
    if (!this.canUploadMoreTilesets) {
      return TILESET_NUMBER_ERROR
    }
    if (!this.isTilesetNameValid) {
      return TILESET_NAME_LENGTH_ERROR
    }
  }

  private get hasSomeError() {
    return (
      !this.isSizeValid ||
      !this.canUploadMoreTilesets ||
      !this.isTilesetNameValid
    )
  }

  private get title(): string | JSX.Element {
    const { isUploading, tilesetFile, sitemapPdfFile, basemapFile } =
      this.props.store
    if (isUploading) {
      return <Loader hint={uploadMapFile} />
    }
    if (tilesetFile) {
      return (
        <div className="text large">
          {tilesetUploaded} {tilesetFile.name}
        </div>
      )
    }
    if (basemapFile) {
      return (
        <div className="text large">
          {planUploaded} {basemapFile.name}
        </div>
      )
    }
    if (sitemapPdfFile) {
      return (
        <div className="text large">
          {planUploaded} {sitemapPdfFile.name}
        </div>
      )
    }

    return (
      <div className="col text large no-grow x-center">
        <span>
          <Icons.Upload className="no-grow" /> {dragMapFileFileHere}
        </span>
        <span>{this.acceptedExtensionsText}</span>
      </div>
    )
  }

  private renderBrowseComputerLine = (): JSX.Element => {
    return (
      <div>
        <FileInput
          className="top-controls-upload-csv-button tooltip-menu-option"
          inputProps={{
            onChange: this.setMapFileFromBrowse,
            accept: this.acceptedExtensions.join(EXTENSIONS_SEPARATOR),
            value: EMPTY_STRING,
          }}
          text={
            <span className="row text tooltip-menu-option px10 large pointer light-blue bold x-end">
              {!this.props.store.basemap
                ? browseYourHardDrive
                : Localization.translator.change_verb}
            </span>
          }
        />
      </div>
    )
  }

  public setMapFileFromBrowse = (event): void => {
    this.setMapFile(event.target.files[0] as File)
  }

  public setMapFile = (file: File): void => {
    this.props.store.resetMapFIleSelection()
    const extention = '.' + file.name.split('.').at(-1)
    if (TILESET_EXTENSIONS.includes(extention)) {
      this.props.store.setTileFile(file)
    }
    if (PLAN_EXTENSIONS.includes(extention)) {
      this.props.store.setSitemapBasemap(file)
    }
  }

  private get isTilesetNameValid(): boolean {
    const { tilesetFile } = this.props.store
    return !tilesetFile || tilesetFile.name.length < MAX_NAME_LENGTH
  }

  private get isSizeValid(): boolean {
    const { tilesetFile } = this.props.store
    const { maxTilesetSizeInBytes } = this.props.state
    return !tilesetFile || tilesetFile.size <= maxTilesetSizeInBytes
  }

  private get canUploadMoreTilesets(): boolean {
    const { tilesetFile } = this.props.store
    const {
      tilesetsConfiguration: { maxNumber },
      tilesets,
    } = this.props.state
    return !tilesetFile || tilesets?.size < maxNumber
  }
}
